export const formatISODateMMDDYYYY = (input: string) => {
  const date = new Date(input);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().padStart(4, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${day}/${month}/${year}`;
};

export const formatDateMonthYear = (input: string) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const date = new Date(input);
  const monthName = monthNames[date.getMonth()];
  const year = date.getFullYear().toString().padStart(4, "0");
  return `${monthName} ${year}`;
};

export const formatDate = (input: string) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(input);
  const day = date.getDate().toString();
  const monthName = monthNames[date.getMonth()];
  const year = date.getFullYear().toString().padStart(4, "0");
  return `${day} ${monthName} ${year}`;
};
