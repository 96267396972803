<template>
  <p>
    {{ $t("ResultsIndicatorShowing") }}
    <strong>{{ first }} - {{ last }}</strong> {{ $t("ResultsIndicatorOf") }}
    <strong>{{ total }} </strong> {{ $t("ResultsIndicatorResults") }}
    <span v-if="term !== ''"> for </span>
    <strong v-if="term !== ''"> '{{ term }}' </strong>
  </p>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "ResultsIndicator",
  props: {
    first: {
      type: Number,
      required: true,
      default: 0,
    },
    last: {
      type: Number,
      required: true,
      default: 0,
    },
    total: {
      type: Number,
      required: true,
      default: 0,
    },
    term: {
      type: String,
      required: true,
      default: "",
    },
    indexTitle: {
      type: String,
      required: true,
      default: "",
    },
  },
});
</script>
