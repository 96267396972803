import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
      return (_openBlock(), _createElementBlock("button", {
        class: "filterItem",
        key: tag.id,
        onClick: ($event: any) => (_ctx.clearTag(tag))
      }, _toDisplayString(tag.title), 9, _hoisted_1))
    }), 128))
  ]))
}