<template>
  <div>
    <label :for="removeWhitespace(name)">{{ name }}</label>
    <select v-model="selectedOption" :id="removeWhitespace(name)">
      <option
        v-if="!useLabelAsFirstOption && useDefaultOption"
        :value="resetObject"
        default
      >
        {{ label }}
      </option>
      <option
        v-if="useLabelAsFirstOption && useDefaultOption"
        disabled="disabled"
        :value="null"
        default
      >
        {{ name }}
      </option>
      <option v-for="option in uniqueTags" :value="option" :key="option.id">
        {{ option.title }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { TaxonomyTag } from "search-api-client";
import { computed, defineComponent, ref, watch } from "vue";
export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
      default: "",
    },
    open: {
      type: Boolean,
    },
    options: [],
    useLabelAsFirstOption: {
      type: Boolean,
    },
    label: {
      required: false,
      type: String,
    },
    active: {
      type: [],
      required: true,
      default: () => [],
    },
    useDefaultOption: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const selectedOption = ref(null as TaxonomyTag);
    const uniqueTags = computed(() => {
      const key = "title";
      return [
        ...new Map(props.options.map((item) => [item[key], item])).values(),
      ];
    });
    const tabIndex = computed(() => (props.active ? 0 : -1));
    const resetObject = {
      title: "reset",
      id: "",
      categoryId: "",
      count: 0,
    };
    if (!props.useLabelAsFirstOption) {
      selectedOption.value = resetObject;
    }

    if (!props.useDefaultOption) {
      selectedOption.value = uniqueTags.value[0] as TaxonomyTag;
    }
    watch(
      () => selectedOption.value,
      (newVal) => {
        if (selectedOption.value !== null) {
          const emitObject = {
            title: selectedOption.value.title,
            id: selectedOption.value.id,
            categoryId: selectedOption.value.categoryId,
            count: selectedOption.value.count,
          } as TaxonomyTag;
          context.emit("inputChange", emitObject);
          if (!props.useLabelAsFirstOption) {
            selectedOption.value = newVal;
          } else {
            selectedOption.value = null;
          }
        }
      },
    );

    const isChecked = (id: string): boolean => {
      return (
        props.active
          .map(function (e: TaxonomyTag) {
            return e.id;
          })
          .indexOf(id) > -1
      );
    };
    const showCheckboxes = () => {
      context.emit("toggleFilters");
    };
    const removeWhitespace = (a) => {
      return a.toLowerCase().split(" ").join("");
    };

    return {
      removeWhitespace,
      showCheckboxes,
      isChecked,
      selectedOption,
      tabIndex,
      resetObject,
      uniqueTags,
    };
  },
});
</script>
