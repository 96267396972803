<template>
  <div>
    <button
      class="filterItem"
      v-for="tag in tags"
      :key="tag.id"
      v-on:click="clearTag(tag)"
    >
      {{ tag.title }}
    </button>
  </div>
</template>

<script lang="ts">
import { TaxonomyTag } from "search-api-client";

import { defineComponent } from "vue";
export default defineComponent({
  props: {
    tags: {
      type: Array as () => TaxonomyTag[],
      required: true,
      default: () => [],
    },
  },
  setup(props, context) {
    const clearTag = (tag: TaxonomyTag) => {
      context.emit("clearTag", tag);
    };
    return {
      clearTag,
    };
  },
});
</script>
