import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", null, [
    _createTextVNode(_toDisplayString(_ctx.$t("ResultsIndicatorShowing")) + " ", 1),
    _createElementVNode("strong", null, _toDisplayString(_ctx.first) + " - " + _toDisplayString(_ctx.last), 1),
    _createTextVNode(" " + _toDisplayString(_ctx.$t("ResultsIndicatorOf")) + " ", 1),
    _createElementVNode("strong", null, _toDisplayString(_ctx.total), 1),
    _createTextVNode(" " + _toDisplayString(_ctx.$t("ResultsIndicatorResults")) + " ", 1),
    (_ctx.term !== '')
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, " for "))
      : _createCommentVNode("", true),
    (_ctx.term !== '')
      ? (_openBlock(), _createElementBlock("strong", _hoisted_2, " '" + _toDisplayString(_ctx.term) + "' ", 1))
      : _createCommentVNode("", true)
  ]))
}