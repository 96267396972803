import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 1,
  disabled: "disabled",
  value: null,
  default: ""
}
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      for: _ctx.removeWhitespace(_ctx.name)
    }, _toDisplayString(_ctx.name), 9, _hoisted_1),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOption) = $event)),
      id: _ctx.removeWhitespace(_ctx.name)
    }, [
      (!_ctx.useLabelAsFirstOption && _ctx.useDefaultOption)
        ? (_openBlock(), _createElementBlock("option", {
            key: 0,
            value: _ctx.resetObject,
            default: ""
          }, _toDisplayString(_ctx.label), 9, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.useLabelAsFirstOption && _ctx.useDefaultOption)
        ? (_openBlock(), _createElementBlock("option", _hoisted_4, _toDisplayString(_ctx.name), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uniqueTags, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          value: option,
          key: option.id
        }, _toDisplayString(option.title), 9, _hoisted_5))
      }), 128))
    ], 8, _hoisted_2), [
      [_vModelSelect, _ctx.selectedOption]
    ])
  ]))
}