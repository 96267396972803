<template>
  <div id="app" class="indexPage">
    <section class="ConsumerFilterBanner">
      <div class="container">
        <RenderResources
          :indexName="index"
          :endPoint="apiendpoint"
          :baseUri="baseUri"
          :filteryear="filteryear"
          :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
          :parentid="parentid"
          :page="1"
          :filtersRequired="1"
        >
          <template v-slot:search="{ result, loading, error }">
            <section class="searchFilterConsumer">
              <div class="error" v-if="error">{{ $t("LoadingError") }}</div>
              <div class="loading" v-if="loading && !error">
                <p>{{ $t("LoadingFilters") }}</p>
              </div>

              <form @submit.prevent="updateKeyword">
                <Filters
                  :tagGroups="result.facets"
                  :showYearFilter="filteryear"
                  @tagUpdated="updateIndexTags(tags)"
                  @changeDate="updateYearTags(tags)"
                  @toggleFilters="switchFilterOverlay"
                  :tags="tags"
                  :labelName="'Ports for'"
                  :label="'all islands'"
                  :useLabelAsFirstOption="false"
                  :datetags="selectedYears"
                  :filtergroupids="filtergroupids"
                  class="selectWrapper"
                ></Filters>
              </form>

              <div class="tabsWrapper">
                <button
                  class="tab"
                  :class="{ active: isGridView }"
                  @click="toggleGridView()"
                  ref="gridToggle"
                >
                  <img
                    src="/assets/images/icons/gridView-icon.svg"
                    role="presentation"
                    loading="lazy"
                    alt
                  />
                  Grid view
                </button>

                <button
                  class="tab"
                  :class="{ active: isListView }"
                  @click="toggleListView()"
                  ref="listToggle"
                >
                  <img
                    src="/assets/images/icons/listView-icon.svg"
                    role="presentation"
                    loading="lazy"
                    alt
                  />
                  List view
                </button>
              </div>
            </section>
          </template>
        </RenderResources>
      </div>
    </section>

    <RenderResources
      :parentid="parentid"
      :indexName="index"
      :endPoint="apiendpoint"
      :tags="selectedTags"
      :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
      :years="selectedYears"
      :baseUri="baseUri"
      :page="currentActive"
      :keyword="keywordInput"
      :pagesize="pagesize"
      :sortorder="[orderBy]"
      @change="resourcesChanged($event, pagesize)"
    >
      <template v-slot:search="{ result, loading, error }">
        <div class="resultSort" id="results">
          <div class="container">
            <div class="error" v-if="error">
              {{ $t("LoadingResourceError") }}
            </div>
            <div class="loading" v-if="loading && !error">
              <p>{{ $t("LoadingResults") }}</p>
            </div>
            <div class="gridListItemsWrapper">
              <div
                class="gridListItems gridView"
                :class="{ active: isGridView }"
                ref="gridItems"
                v-if="
                  !loading &&
                  !error &&
                  result.documents !== undefined &&
                  result.documents.length > 0
                "
              >
                <component
                  :is="resultType"
                  v-for="responseValueItem in result.documents"
                  :key="responseValueItem.id"
                  :result="responseValueItem"
                ></component>
              </div>

              <div
                class="gridListItems listView"
                :class="{ active: isListView }"
                ref="listItems"
                v-if="
                  !loading &&
                  !error &&
                  result.documents !== undefined &&
                  result.documents.length > 0
                "
              >
                <component
                  :is="resultType"
                  v-for="responseValueItem in result.documents"
                  :key="responseValueItem.id"
                  :result="responseValueItem"
                ></component>
              </div>
            </div>

            <button class="backToTop" @click="scrollToTop()">
              Back to top
              <svg
                aria-hidden="true"
                role="presentation"
                width="13"
                height="15"
                viewBox="0 0 13 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.51812 -0.000546238L7.93234 1.41367L7.93179 1.41422L12.1746 5.657L10.7604 7.07121L7.51801 3.82887L7.51801 14.0356L5.51801 14.0356L5.51801 3.828L2.27548 7.07052L0.86127 5.65631L6.51812 -0.000546238Z"
                />
              </svg>
            </button>

            <div
              class="noResults"
              v-if="
                !loading &&
                !error &&
                (result === undefined || result.count === 0)
              "
            >
              <p>{{ $t("NoResults") }}</p>
            </div>
          </div>
        </div>
      </template>
    </RenderResources>
  </div>
</template>

<script lang="ts">
import Pagination from "v-pagination-3";

import ChosenTags from "../components/ChosenTags.vue";
import ChosenDateTags from "../components/ChosenDateTags.vue";
import Filters from "../components/Filters.vue";
import ResultsIndicator from "../components/ResultsIndicator.vue";
import ResultPorts from "../components/ResultPorts.vue";
import RenderResources from "../components/renderless/RenderResources.vue";
import { defineComponent, ref } from "vue";
import {
  resourcesChanged,
  clearKeyword,
  updateKeyword,
  updateYearClear,
  updateYearTags,
  updateClear,
  updateTags,
  convertTagtoSearchTag,
  updateIndexTags,
  paginationClick,
  lastPage,
  switchFilterOverlay,
  clearDateTag,
  clear,
  clearAll,
  firstPage,
  toggleFiltersInView,
  tags,
  totalPages,
  selectedYears,
  selectedTags,
  currentActive,
  keywordInput,
  keyword,
  toggleListView,
  toggleGridView,
  isGridView,
  isListView,
} from "./common";
export default defineComponent({
  components: {
    Filters,
    ResultsIndicator,
    ResultPorts,
    Pagination,
    RenderResources,
    ChosenTags,
    ChosenDateTags,
  },
  props: {
    showkeywords: {
      type: Boolean,
      required: true,
      default: true,
    },
    filtergroupids: {
      type: [],
      required: true,
      default: [],
    },
    filteryear: {
      type: Boolean,
      required: true,
      default: true,
    },
    ocpApimSubscriptionKey: {
      type: String,
      required: false,
      default: "",
    },
    parentid: {
      type: Number,
      required: true,
      default: -1,
    },
    pagesize: {
      type: Number,
      required: true,
      default: 20,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    resultType: {
      type: String,
      required: true,
      default: "",
    },
    indexName: {
      type: String,
      required: true,
      default: "",
    },
    baseUri: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(props) {
    const index = props.indexName;
    const apiendpoint = `${props.baseUri}`;
    const sortOptions = ["A-Z", "Z-A"] as string[];
    const sortOption = ref("A-Z" as string);
    const orderBy = ref("title asc" as string);

    return {
      resourcesChanged,
      clearKeyword,
      updateKeyword,
      updateYearClear,
      updateYearTags,
      updateClear,
      updateTags,
      convertTagtoSearchTag,
      paginationClick,
      lastPage,
      switchFilterOverlay,
      clearDateTag,
      clear,
      clearAll,
      updateIndexTags,
      firstPage,
      toggleListView,
      toggleGridView,
      apiendpoint,
      index,
      toggleFiltersInView,
      tags,
      totalPages,
      orderBy,
      selectedYears,
      selectedTags,
      sortOption,
      sortOptions,
      currentActive,
      keywordInput,
      keyword,
      isGridView,
      isListView,
    };
  },

  methods: {
    toggleListActive() {
      const listToggle = this.$refs.listToggle;
      const gridToggle = this.$refs.gridToggle;
      const listItems = this.$refs.listItems;
      const gridItems = this.$refs.gridItems;

      gridToggle.classList.remove("active");
      gridItems.classList.remove("active");
      listToggle.classList.add("active");
      listItems.classList.add("active");
    },

    toggleGridActive() {
      const listToggle = this.$refs.listToggle;
      const gridToggle = this.$refs.gridToggle;
      const listItems = this.$refs.listItems;
      const gridItems = this.$refs.gridItems;

      gridToggle.classList.add("active");
      listToggle.classList.remove("active");
      gridItems.classList.add("active");
      listItems.classList.remove("active");
    },

    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
});
</script>
