<template>
  <a :href="result.url" class="item" :aria-label="result.title">
    <div class="itemPreview grid" v-if="result.images.length > 0">
      <img
        :src="result.images[0].url"
        :alt="result.images[0].altText"
        loading="lazy"
      />
    </div>
    <div class="itemPreview list" v-if="result.images.length > 1">
      <img
        :src="result.images[1].url"
        :alt="result.images[1].altText"
        loading="lazy"
      />
    </div>
    <div class="itemInner mobileIconOnly port">
      <h2 class="h4">
        <svg
          aria-hidden="true"
          role="presentation"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.975 4.55486C7.97253 4.1503 8.09024 3.75414 8.31317 3.41672C8.5361 3.07931 8.85418 2.81588 9.22699 2.65992C9.5998 2.50395 10.0105 2.4625 10.4069 2.54082C10.8033 2.61914 11.1675 2.8137 11.4532 3.09977C11.7389 3.38585 11.9332 3.75051 12.0114 4.14743C12.0896 4.54434 12.0482 4.95558 11.8925 5.32888C11.7367 5.70218 11.4736 6.02068 11.1367 6.2439C10.7997 6.46712 10.404 6.58498 10 6.58251C9.46395 6.57922 8.95078 6.36454 8.57173 5.98499C8.19268 5.60545 7.97828 5.09161 7.975 4.55486ZM15.95 8.4224L11.8875 12.4902H15C14.9964 13.5973 14.6264 14.6721 13.9479 15.5462C13.2694 16.4204 12.3206 17.0447 11.25 17.3215V8.88551C12.2983 8.5838 13.2025 7.91308 13.7961 6.99682C14.3898 6.08056 14.6329 4.98042 14.4808 3.89891C14.3286 2.81741 13.7914 1.82733 12.9681 1.11092C12.1448 0.394513 11.0908 0 10 0C8.90924 0 7.85519 0.394513 7.03187 1.11092C6.20855 1.82733 5.67138 2.81741 5.51924 3.89891C5.36709 4.98042 5.61021 6.08056 6.20385 6.99682C6.79749 7.91308 7.70169 8.5838 8.75 8.88551V17.3215C7.67941 17.0447 6.73063 16.4204 6.05211 15.5462C5.37359 14.6721 5.00359 13.5973 5 12.4902H8.125L4.0625 8.4224L0 12.4902H2.5C2.5 14.4819 3.29018 16.3921 4.6967 17.8004C6.10322 19.2088 8.01088 20 10 20C11.9891 20 13.8968 19.2088 15.3033 17.8004C16.7098 16.3921 17.5 14.4819 17.5 12.4902H20L15.95 8.4224Z"
          />
        </svg>
        {{ result.title }}
      </h2>
      <div class="meta">
        {{ result.description }}
      </div>
    </div>
  </a>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import { SearchResponseDocument } from "search-api-client";
export default defineComponent({
  props: {
    result: {
      type: Object as () => SearchResponseDocument,
      required: true,
      default: () => {},
    },
  },
});
</script>
