import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  value: null,
  default: ""
}
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      for: _ctx.removeWhitespace(_ctx.name)
    }, _toDisplayString(_ctx.name), 9, _hoisted_1),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOption) = $event)),
      id: _ctx.removeWhitespace(_ctx.name)
    }, [
      _createElementVNode("option", _hoisted_3, _toDisplayString(_ctx.name), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          value: option,
          key: option.value
        }, _toDisplayString(option.value.getFullYear()), 9, _hoisted_4))
      }), 128))
    ], 8, _hoisted_2), [
      [_vModelSelect, _ctx.selectedOption]
    ])
  ]))
}