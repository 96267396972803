import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterDropDown = _resolveComponent("FilterDropDown")!
  const _component_DateFilterDropDown = _resolveComponent("DateFilterDropDown")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.restrictCategories(
        _ctx.filtergroupids,
        0,
        3,
        _ctx.tagGroups.taxonomy,
      ), (taxonomy, index) => {
      return (_openBlock(), _createBlock(_component_FilterDropDown, {
        key: taxonomy.id,
        options: taxonomy.tags,
        active: _ctx.tags,
        ref_for: true,
        ref: taxonomy.id,
        open: _ctx.activeGroup[index],
        name: _ctx.labelNameFn(taxonomy.title),
        useLabelAsFirstOption: _ctx.useLabelAsFirstOption,
        label: _ctx.labelFn(_ctx.label),
        onInputChange: _ctx.addFilter,
        onToggleFilters: ($event: any) => (_ctx.toggleFilters(index, _ctx.activeGroup[index])),
        class: "selectInput"
      }, null, 8, ["options", "active", "open", "name", "useLabelAsFirstOption", "label", "onInputChange", "onToggleFilters"]))
    }), 128)),
    (_ctx.showYearFilter)
      ? (_openBlock(), _createBlock(_component_DateFilterDropDown, {
          key: 0,
          options: _ctx.tagGroups.created,
          active: _ctx.datetags,
          name: _ctx.$t('PublishDate'),
          open: _ctx.showmore,
          onInputChange: _ctx.addDateFilter,
          onToggleFilters: _ctx.showYearFilters,
          class: "selectInput"
        }, null, 8, ["options", "active", "name", "open", "onInputChange", "onToggleFilters"]))
      : _createCommentVNode("", true)
  ]))
}