import { createApp, h } from "vue";

import i18n from "./i18n";
import App from "./App.vue";
import config from "./config";

const app = createApp({
  render: () => h(App, config),
});
app.use(i18n);

app.mount("#sm-resource-index");
