<template>
  <div>
    <News
      v-if="resultType === 'ResultNews'"
      :showkeywords="showkeywords"
      :filtergroupids="filtergroupids"
      :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
      :filteryear="filteryear"
      :parentid="parentid"
      :pagesize="pagesize"
      :title="title"
      :resultType="resultType"
      :indexName="indexName"
      :baseUri="baseUri"
    ></News>

    <Resources
      v-if="resultType === 'ResultResource'"
      :showkeywords="showkeywords"
      :filtergroupids="filtergroupids"
      :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
      :filteryear="filteryear"
      :parentid="parentid"
      :pagesize="pagesize"
      :title="title"
      :resultType="resultType"
      :indexName="indexName"
      :baseUri="baseUri"
    ></Resources>
    <Blog
      v-if="resultType === 'ResultBlog'"
      :showkeywords="showkeywords"
      :filtergroupids="filtergroupids"
      :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
      :filteryear="filteryear"
      :parentid="parentid"
      :pagesize="pagesize"
      :title="title"
      :resultType="resultType"
      :indexName="indexName"
      :baseUri="baseUri"
    ></Blog>
    <Destination
      v-if="resultType === 'ResultDestination'"
      :showkeywords="showkeywords"
      :filtergroupids="filtergroupids"
      :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
      :filteryear="filteryear"
      :parentid="parentid"
      :pagesize="pagesize"
      :title="title"
      :resultType="resultType"
      :indexName="indexName"
      :baseUri="baseUri"
      :maxItems="maxItems"
      :showListGridToggle="showListGridToggle"
      :showBackToTop="showBackToTop"
      :description="description"
      :filterTagIds="filterTagIds"
    ></Destination>
    <Ports
      v-if="resultType === 'ResultPorts'"
      :showkeywords="showkeywords"
      :filtergroupids="filtergroupids"
      :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
      :filteryear="filteryear"
      :parentid="parentid"
      :pagesize="pagesize"
      :title="title"
      :resultType="resultType"
      :indexName="indexName"
      :baseUri="baseUri"
    ></Ports>
    <Vessel
      v-if="resultType === 'ResultVessel'"
      :showkeywords="showkeywords"
      :filtergroupids="filtergroupids"
      :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
      :filteryear="filteryear"
      :parentid="parentid"
      :pagesize="pagesize"
      :title="title"
      :resultType="resultType"
      :indexName="indexName"
      :baseUri="baseUri"
    ></Vessel>
    <HelpCentre
      v-if="resultType === 'ResultTopic'"
      :showkeywords="showkeywords"
      :filtergroupids="filtergroupids"
      :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
      :filteryear="filteryear"
      :parentid="parentid"
      :pagesize="pagesize"
      :title="title"
      :resultType="resultType"
      :indexName="indexName"
      :baseUri="baseUri"
    ></HelpCentre>
    <Search
      v-if="resultType === 'ResultSearch'"
      :showkeywords="showkeywords"
      :filtergroupids="filtergroupids"
      :filteryear="filteryear"
      :parentid="parentid"
      :pagesize="pagesize"
      :title="title"
      :resultType="resultType"
      :indexName="indexName"
      :baseUri="baseUri"
    ></Search>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import News from "./containers/News.vue";
import Resources from "./containers/Resources.vue";
import Blog from "./containers/Blog.vue";
import Destination from "./containers/Destination.vue";
import Ports from "./containers/Ports.vue";
import Vessel from "./containers/Vessel.vue";
import HelpCentre from "./containers/HelpCentre.vue";
import Search from "./containers/Search.vue";
export default defineComponent({
  components: {
    News,
    Resources,
    Blog,
    Destination,
    Ports,
    Vessel,
    HelpCentre,
    Search,
  },
  props: {
    showkeywords: {
      type: Boolean,
      required: true,
      default: true,
    },
    filtergroupids: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => [],
    },
    ocpApimSubscriptionKey: {
      type: String,
      required: false,
      default: "",
    },
    filteryear: {
      type: Boolean,
      required: true,
      default: true,
    },
    parentid: {
      type: Number,
      required: true,
      default: -1,
    },
    pagesize: {
      type: Number,
      required: true,
      default: 20,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    description: {
      type: String,
      required: true,
      default: "",
    },
    resultType: {
      type: String,
      required: true,
      default: "",
    },
    indexName: {
      type: String,
      required: true,
      default: "",
    },
    baseUri: {
      type: String,
      required: true,
      default: "",
    },
    showListGridToggle: {
      type: Boolean,
      required: false,
      default: true,
    },
    showBackToTop: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxItems: {
      type: Number,
      required: false,
      default: 0,
    },
    filterTagIds: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => [],
    },
  },
});
</script>
