import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterDropDown = _resolveComponent("FilterDropDown")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.restrictCategories(
        _ctx.filtergroupids,
        0,
        3,
        _ctx.tagGroups.taxonomy,
      ), (taxonomy, index) => {
      return (_openBlock(), _createBlock(_component_FilterDropDown, {
        key: taxonomy.id,
        options: _ctx.restrictTags(_ctx.filterTagIds, taxonomy.tags),
        active: _ctx.tags,
        ref_for: true,
        ref: taxonomy.id,
        open: _ctx.activeGroup[index],
        name: _ctx.labelNameFn(taxonomy.title),
        useLabelAsFirstOption: _ctx.useLabelAsFirstOption,
        label: _ctx.labelFn(_ctx.label),
        useDefaultOption: _ctx.filterTagIds.length === 0,
        onInputChange: _ctx.addFilter,
        onToggleFilters: ($event: any) => (_ctx.toggleFilters(index, _ctx.activeGroup[index])),
        class: "selectInput"
      }, null, 8, ["options", "active", "open", "name", "useLabelAsFirstOption", "label", "useDefaultOption", "onInputChange", "onToggleFilters"]))
    }), 128))
  ]))
}