<template>
  <div>
    <FilterDropDown
      v-for="(taxonomy, index) in restrictCategories(
        filtergroupids,
        0,
        3,
        tagGroups.taxonomy,
      )"
      :key="taxonomy.id"
      :options="restrictTags(filterTagIds, taxonomy.tags)"
      :active="tags"
      :ref="taxonomy.id"
      :open="activeGroup[index]"
      :name="labelNameFn(taxonomy.title)"
      :useLabelAsFirstOption="useLabelAsFirstOption"
      :label="labelFn(label)"
      :useDefaultOption="filterTagIds.length === 0"
      @inputChange="addFilter"
      @toggleFilters="toggleFilters(index, activeGroup[index])"
      class="selectInput"
    ></FilterDropDown>
  </div>
</template>

<script lang="ts">
import {
  GenericSearchResponseFacets,
  TaxonomyCategory,
  TaxonomyTag,
} from "search-api-client";

import FilterDropDown from "./FilterDropDown.vue";

import { computed, defineComponent, ref, watch } from "vue";
export default defineComponent({
  components: {
    FilterDropDown,
  },
  props: {
    tagGroups: {
      required: true,
      default: {},
    },
    tags: {
      type: [],
      required: true,
      default: [],
    },
    datetags: {
      type: [],
      required: true,
      default: [],
    },
    filtergroupids: {
      type: [],
      required: false,
      default: [],
    },
    labelName: {
      required: false,
    },
    useLabelAsFirstOption: {
      type: Boolean,
      default: true,
    },
    label: {
      required: false,
    },
    filterTagIds: {
      type: [],
      required: false,
      default: [],
    },
  },
  setup(props, context) {
    const activeGroup = ref(props.filtergroupids.map(() => false) as boolean[]);
    const showmore = ref(false);

    const tabIndexMore = computed(() => (showmore.value ? 0 : -1));

    const labelNameFn = (labelText: string) => {
      return props.labelName === undefined || props.labelName === ""
        ? labelText
        : props.labelName;
    };
    const labelFn = (title: string) => {
      return props.label === undefined || props.label === ""
        ? title
        : props.label;
    };

    const restrictCategories = (
      ids: string[],
      start: number,
      end: number,
      categories?: TaxonomyCategory[],
    ): TaxonomyCategory[] => {
      if (categories !== undefined) {
        if (ids === undefined || ids.length === 0)
          return categories.filter(function (
            cat: TaxonomyCategory,
            index: number,
          ) {
            return index < end && index >= start;
          });
        if (!categories) return [];
        console.log("CATS", categories);
        return categories.filter(function (
          cat: TaxonomyCategory,
          index: number,
        ) {
          return (
            ids.includes(cat.id.toString()) && index < end && index >= start
          );
        });
      }
      return [];
    };

    const restrictTags = (
      ids: string[],
      tags: TaxonomyTag[],
    ): TaxonomyTag[] => {
      if (ids === undefined || ids.length === 0) {
        return tags;
      }
      if (!tags) return [];
      return tags.filter((tag) => ids.includes(tag.id.toString()));
    };

    const toggleFilters = (index: number, isOpen: boolean) => {
      showmore.value = false;
      activeGroup.value = props.filtergroupids.map(() => false);
      if (!isOpen) {
        activeGroup.value[index] = true;
        context.emit("toggleFilters", true);
      } else {
        context.emit("toggleFilters", false);
      }
    };
    const addFilter = (tag: TaxonomyTag) => {
      if (props.tags.filter((x) => x.id === tag.id).length === 0) {
        props.tags.push(tag);
        context.emit("tagUpdated", props.tags);
      }
    };
    watch(
      () => props.tagGroups,
      (newVal: GenericSearchResponseFacets) => {
        activeGroup.value = restrictCategories(
          props.filtergroupids,
          0,
          newVal.taxonomy.length - 1,
          newVal.taxonomy,
        ).map(() => false);
      },
    );

    return {
      addFilter,
      toggleFilters,
      restrictCategories,
      restrictTags,
      tabIndexMore,
      showmore,
      activeGroup,
      labelFn,
      labelNameFn,
    };
  },
});
</script>
