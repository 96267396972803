import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_News = _resolveComponent("News")!
  const _component_Resources = _resolveComponent("Resources")!
  const _component_Blog = _resolveComponent("Blog")!
  const _component_Destination = _resolveComponent("Destination")!
  const _component_Ports = _resolveComponent("Ports")!
  const _component_Vessel = _resolveComponent("Vessel")!
  const _component_HelpCentre = _resolveComponent("HelpCentre")!
  const _component_Search = _resolveComponent("Search")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.resultType === 'ResultNews')
      ? (_openBlock(), _createBlock(_component_News, {
          key: 0,
          showkeywords: _ctx.showkeywords,
          filtergroupids: _ctx.filtergroupids,
          ocpApimSubscriptionKey: _ctx.ocpApimSubscriptionKey,
          filteryear: _ctx.filteryear,
          parentid: _ctx.parentid,
          pagesize: _ctx.pagesize,
          title: _ctx.title,
          resultType: _ctx.resultType,
          indexName: _ctx.indexName,
          baseUri: _ctx.baseUri
        }, null, 8, ["showkeywords", "filtergroupids", "ocpApimSubscriptionKey", "filteryear", "parentid", "pagesize", "title", "resultType", "indexName", "baseUri"]))
      : _createCommentVNode("", true),
    (_ctx.resultType === 'ResultResource')
      ? (_openBlock(), _createBlock(_component_Resources, {
          key: 1,
          showkeywords: _ctx.showkeywords,
          filtergroupids: _ctx.filtergroupids,
          ocpApimSubscriptionKey: _ctx.ocpApimSubscriptionKey,
          filteryear: _ctx.filteryear,
          parentid: _ctx.parentid,
          pagesize: _ctx.pagesize,
          title: _ctx.title,
          resultType: _ctx.resultType,
          indexName: _ctx.indexName,
          baseUri: _ctx.baseUri
        }, null, 8, ["showkeywords", "filtergroupids", "ocpApimSubscriptionKey", "filteryear", "parentid", "pagesize", "title", "resultType", "indexName", "baseUri"]))
      : _createCommentVNode("", true),
    (_ctx.resultType === 'ResultBlog')
      ? (_openBlock(), _createBlock(_component_Blog, {
          key: 2,
          showkeywords: _ctx.showkeywords,
          filtergroupids: _ctx.filtergroupids,
          ocpApimSubscriptionKey: _ctx.ocpApimSubscriptionKey,
          filteryear: _ctx.filteryear,
          parentid: _ctx.parentid,
          pagesize: _ctx.pagesize,
          title: _ctx.title,
          resultType: _ctx.resultType,
          indexName: _ctx.indexName,
          baseUri: _ctx.baseUri
        }, null, 8, ["showkeywords", "filtergroupids", "ocpApimSubscriptionKey", "filteryear", "parentid", "pagesize", "title", "resultType", "indexName", "baseUri"]))
      : _createCommentVNode("", true),
    (_ctx.resultType === 'ResultDestination')
      ? (_openBlock(), _createBlock(_component_Destination, {
          key: 3,
          showkeywords: _ctx.showkeywords,
          filtergroupids: _ctx.filtergroupids,
          ocpApimSubscriptionKey: _ctx.ocpApimSubscriptionKey,
          filteryear: _ctx.filteryear,
          parentid: _ctx.parentid,
          pagesize: _ctx.pagesize,
          title: _ctx.title,
          resultType: _ctx.resultType,
          indexName: _ctx.indexName,
          baseUri: _ctx.baseUri,
          maxItems: _ctx.maxItems,
          showListGridToggle: _ctx.showListGridToggle,
          showBackToTop: _ctx.showBackToTop,
          description: _ctx.description,
          filterTagIds: _ctx.filterTagIds
        }, null, 8, ["showkeywords", "filtergroupids", "ocpApimSubscriptionKey", "filteryear", "parentid", "pagesize", "title", "resultType", "indexName", "baseUri", "maxItems", "showListGridToggle", "showBackToTop", "description", "filterTagIds"]))
      : _createCommentVNode("", true),
    (_ctx.resultType === 'ResultPorts')
      ? (_openBlock(), _createBlock(_component_Ports, {
          key: 4,
          showkeywords: _ctx.showkeywords,
          filtergroupids: _ctx.filtergroupids,
          ocpApimSubscriptionKey: _ctx.ocpApimSubscriptionKey,
          filteryear: _ctx.filteryear,
          parentid: _ctx.parentid,
          pagesize: _ctx.pagesize,
          title: _ctx.title,
          resultType: _ctx.resultType,
          indexName: _ctx.indexName,
          baseUri: _ctx.baseUri
        }, null, 8, ["showkeywords", "filtergroupids", "ocpApimSubscriptionKey", "filteryear", "parentid", "pagesize", "title", "resultType", "indexName", "baseUri"]))
      : _createCommentVNode("", true),
    (_ctx.resultType === 'ResultVessel')
      ? (_openBlock(), _createBlock(_component_Vessel, {
          key: 5,
          showkeywords: _ctx.showkeywords,
          filtergroupids: _ctx.filtergroupids,
          ocpApimSubscriptionKey: _ctx.ocpApimSubscriptionKey,
          filteryear: _ctx.filteryear,
          parentid: _ctx.parentid,
          pagesize: _ctx.pagesize,
          title: _ctx.title,
          resultType: _ctx.resultType,
          indexName: _ctx.indexName,
          baseUri: _ctx.baseUri
        }, null, 8, ["showkeywords", "filtergroupids", "ocpApimSubscriptionKey", "filteryear", "parentid", "pagesize", "title", "resultType", "indexName", "baseUri"]))
      : _createCommentVNode("", true),
    (_ctx.resultType === 'ResultTopic')
      ? (_openBlock(), _createBlock(_component_HelpCentre, {
          key: 6,
          showkeywords: _ctx.showkeywords,
          filtergroupids: _ctx.filtergroupids,
          ocpApimSubscriptionKey: _ctx.ocpApimSubscriptionKey,
          filteryear: _ctx.filteryear,
          parentid: _ctx.parentid,
          pagesize: _ctx.pagesize,
          title: _ctx.title,
          resultType: _ctx.resultType,
          indexName: _ctx.indexName,
          baseUri: _ctx.baseUri
        }, null, 8, ["showkeywords", "filtergroupids", "ocpApimSubscriptionKey", "filteryear", "parentid", "pagesize", "title", "resultType", "indexName", "baseUri"]))
      : _createCommentVNode("", true),
    (_ctx.resultType === 'ResultSearch')
      ? (_openBlock(), _createBlock(_component_Search, {
          key: 7,
          showkeywords: _ctx.showkeywords,
          filtergroupids: _ctx.filtergroupids,
          filteryear: _ctx.filteryear,
          parentid: _ctx.parentid,
          pagesize: _ctx.pagesize,
          title: _ctx.title,
          resultType: _ctx.resultType,
          indexName: _ctx.indexName,
          baseUri: _ctx.baseUri
        }, null, 8, ["showkeywords", "filtergroupids", "filteryear", "parentid", "pagesize", "title", "resultType", "indexName", "baseUri"]))
      : _createCommentVNode("", true)
  ]))
}