<template>
  <div>
    <FilterDropDown
      v-for="(taxonomy, index) in restrictCategories(
        filtergroupids,
        0,
        3,
        tagGroups.taxonomy,
      )"
      :key="taxonomy.id"
      :options="taxonomy.tags"
      :active="tags"
      :ref="taxonomy.id"
      :open="activeGroup[index]"
      :name="labelNameFn(taxonomy.title)"
      :useLabelAsFirstOption="useLabelAsFirstOption"
      :label="labelFn(label)"
      @inputChange="addFilter"
      @toggleFilters="toggleFilters(index, activeGroup[index])"
      class="selectInput"
    >
    </FilterDropDown>

    <DateFilterDropDown
      :options="tagGroups.created"
      :active="datetags"
      v-if="showYearFilter"
      :name="$t('PublishDate')"
      :open="showmore"
      @inputChange="addDateFilter"
      @toggleFilters="showYearFilters"
      class="selectInput"
    >
    </DateFilterDropDown>
  </div>
</template>

<script lang="ts">
import {
  GenericSearchResponseFacets,
  GenericSearchResponseFacetsCreatedItem,
  TaxonomyCategory,
  TaxonomyTag,
} from "search-api-client";

import FilterDropDown from "./FilterDropDown.vue";
import DateFilterDropDown from "./DateFilterDropDown.vue";

import { computed, defineComponent, ref, watch, PropType } from "vue";
export default defineComponent({
  components: {
    FilterDropDown,
    DateFilterDropDown,
  },
  props: {
    tagGroups: {
      required: true,
      default: {},
    },
    tags: {
      type: Array as PropType<Array<TaxonomyTag>>,
      required: true,
      default: () => [],
    },
    datetags: {
      type: Array as PropType<Array<GenericSearchResponseFacetsCreatedItem>>,
      required: true,
      default: () => [],
    },
    showYearFilter: {
      type: Boolean,
      required: true,
      default: false,
    },
    filtergroupids: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => [],
    },
    labelName: {
      required: false,
    },
    useLabelAsFirstOption: {
      type: Boolean,
      default: true,
    },
    label: {
      required: false,
    },
  },
  setup(props, context) {
    const activeGroup = ref(
      (props.filtergroupids as string[]).map(() => false) as boolean[],
    );
    const showmore = ref(false);

    const tabIndexMore = computed(() => (showmore.value ? 0 : -1));

    const labelNameFn = (labelText: string) => {
      return props.labelName === undefined || props.labelName === ""
        ? labelText
        : props.labelName;
    };
    const labelFn = (title: string) => {
      return props.label === undefined || props.label === ""
        ? title
        : props.label;
    };

    const restrictCategories = (
      ids: string[],
      start: number,
      end: number,
      categories?: TaxonomyCategory[],
    ): TaxonomyCategory[] => {
      if (!categories || categories.length === 0) {
        return [];
      }

      if (ids && ids.length >= 1) {
        return categories.filter(function (
          cat: TaxonomyCategory,
          index: number,
        ) {
          return (
            ids.includes(cat.id.toString()) && index < end && index >= start
          );
        });
      }

      return categories.filter((_: TaxonomyCategory, index: number) => {
        return index < end && index >= start;
      });
    };
    const showYearFilters = () => {
      activeGroup.value = (props.filtergroupids as string[]).map(() => false);
      if (showmore.value) {
        context.emit("toggleFilters", false);
      } else {
        context.emit("toggleFilters", true);
      }
      showmore.value = !showmore.value;
    };
    const toggleFilters = (index: number, isOpen: boolean) => {
      showmore.value = false;
      activeGroup.value = (props.filtergroupids as string[]).map(() => false);
      if (!isOpen) {
        activeGroup.value[index] = true;
        context.emit("toggleFilters", true);
      } else {
        context.emit("toggleFilters", false);
      }
    };
    const addFilter = (tag: TaxonomyTag) => {
      if (
        (props.tags as TaxonomyTag[]).filter((x) => x.id === tag.id).length ===
        0
      ) {
        (props.tags as TaxonomyTag[]).push(tag);
        context.emit("tagUpdated", props.tags);
      }
    };
    const addDateFilter = (dateFilter) => {
      const date = new Date(dateFilter.value);
      context.emit("changeDate", date.getFullYear().toString());
    };
    watch(
      () => props.tagGroups,
      (newVal: GenericSearchResponseFacets) => {
        activeGroup.value = restrictCategories(
          props.filtergroupids as string[],
          0,
          newVal.taxonomy.length - 1,
          newVal.taxonomy,
        ).map(() => false);
      },
    );

    return {
      addDateFilter,
      addFilter,
      toggleFilters,
      showYearFilters,
      restrictCategories,
      tabIndexMore,
      showmore,
      activeGroup,
      labelFn,
      labelNameFn,
    };
  },
});
</script>
