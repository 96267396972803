<template>
  <div id="app" class="background-whitegreygradient indexPage">
    <section
      class="filterBanner"
      :class="[{ moreFilterActive: toggleFiltersInView }]"
    >
      <div class="container"></div>
    </section>
    <RenderResources
      :parentid="parentid"
      :indexName="index"
      :endPoint="apiendpoint"
      :tags="selectedTags"
      :years="selectedYears"
      :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
      :baseUri="baseUri"
      :page="currentActive"
      :keyword="keywordInput"
      :pagesize="pagesize"
      :sortorder="[orderBy]"
      @change="resourcesChanged($event, pagesize)"
    >
      <template v-slot:search="{ result, loading, error }">
        <div class="resultSort" id="results">
          <div class="container">
            <div class="sortInner">
              <div class="form sortBy">
                <label for="indexSortBy">Sort by:</label>
                <select class="select" id="indexSortBy" v-model="orderBy">
                  <option value="created desc">Newest</option>
                  <option value="created asc">Oldest</option>
                </select>
              </div>
            </div>
            <div class="error" v-if="error">
              {{ $t("LoadingResourceError") }}
            </div>
            <div class="loading" v-if="loading && !error">
              <p>{{ $t("LoadingResults") }}</p>
            </div>
            <div
              class="resourceItems"
              v-if="
                !loading &&
                !error &&
                result.documents !== undefined &&
                result.documents.length > 0
              "
            >
              <div class="inner">
                <component
                  :is="resultType"
                  v-for="responseValueItem in result.documents"
                  :key="responseValueItem.id"
                  :result="responseValueItem"
                ></component>
              </div>
            </div>

            <div
              class="noResults"
              v-if="
                !loading &&
                !error &&
                (result === undefined || result.count === 0)
              "
            >
              <p>{{ $t("NoResults") }}</p>
            </div>
          </div>
        </div>
        <div class="container small">
          <nav
            class="pagination"
            v-if="totalPages > 1"
            role="navigation"
            aria-label="Pagination Navigation"
          >
            <pagination
              v-model="currentActive"
              :records="result.count"
              :per-page="pagesize"
              @paginate="paginationClick"
            />
          </nav>
        </div>
      </template>
    </RenderResources>
  </div>
</template>

<script lang="ts">
import Pagination from "v-pagination-3";
import ChosenTags from "../components/ChosenTags.vue";
import ChosenDateTags from "../components/ChosenDateTags.vue";
import Filters from "../components/Filters.vue";
import ResultResource from "../components/ResultResource.vue";
import RenderResources from "../components/renderless/RenderResources.vue";
import { defineComponent, ref } from "vue";
import {
  resourcesChanged,
  clearKeyword,
  updateKeyword,
  updateYearClear,
  updateYearTags,
  updateClear,
  updateTags,
  handleSelectSearchTerm,
  handleKeywordInputChanged,
  convertTagtoSearchTag,
  paginationClick,
  lastPage,
  switchFilterOverlay,
  clearDateTag,
  clear,
  clearAll,
  firstPage,
  toggleFiltersInView,
  tags,
  totalPages,
  selectedYears,
  selectedTags,
  currentActive,
  keywordInput,
  keyword,
  suggestedSearchTerms,
} from "./common";
export default defineComponent({
  components: {
    Filters,
    ResultResource,
    Pagination,
    RenderResources,
    ChosenTags,
    ChosenDateTags,
  },
  props: {
    showkeywords: {
      type: Boolean,
      required: true,
      default: true,
    },
    filtergroupids: {
      type: [],
      required: true,
      default: [],
    },
    filteryear: {
      type: Boolean,
      required: true,
      default: true,
    },
    ocpApimSubscriptionKey: {
      type: String,
      required: false,
      default: "",
    },
    parentid: {
      type: Number,
      required: true,
      default: -1,
    },
    pagesize: {
      type: Number,
      required: true,
      default: 20,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    resultType: {
      type: String,
      required: true,
      default: "",
    },
    indexName: {
      type: String,
      required: true,
      default: "",
    },
    baseUri: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(props) {
    const index = props.indexName;
    const apiendpoint = `${props.baseUri}`;
    const sortOptions = ["A-Z", "Z-A"] as string[];
    const sortOption = ref("A-Z" as string);
    const orderBy = ref("created desc" as string);

    const handleSearchTextKeyDown = (event: KeyboardEvent) => {
      handleKeywordInputChanged({
        event,
        baseUri: props.baseUri as string,
        index: props.indexName as string,
        parentId: props.parentid as number,
      });
    };

    return {
      resourcesChanged,
      clearKeyword,
      updateKeyword,
      updateYearClear,
      updateYearTags,
      updateClear,
      updateTags,
      handleSelectSearchTerm,
      handleSearchTextKeyDown,
      convertTagtoSearchTag,
      paginationClick,
      lastPage,
      switchFilterOverlay,
      clearDateTag,
      clear,
      clearAll,
      firstPage,
      apiendpoint,
      index,
      toggleFiltersInView,
      tags,
      totalPages,
      orderBy,
      selectedYears,
      selectedTags,
      sortOption,
      sortOptions,
      currentActive,
      keywordInput,
      keyword,
      suggestedSearchTerms,
    };
  },
});
</script>
