<template>
  <a :href="result.url" class="item" :aria-describedby="result.id">
    <div class="image" v-if="result.images.length > 0">
      <img :src="result.images[0].url" :alt="result.images[0].altText" />
    </div>

    <div class="image" v-else>
      <img
        src="/assets/images/components/index/newsIndexImagePlaceholder.jpg"
        role="presentation"
        alt=""
      />
    </div>

    <p class="date">{{ formatDate(result.created) }}</p>
    <div class="titleWrapper">
      <h3 class="h5" :id="result.id">
        {{ result.title }}
        <span class="line"></span>
      </h3>
    </div>
  </a>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import { KeyValuePair, SearchResponseDocument } from "search-api-client";
import { formatDate } from "../common/date";
export default defineComponent({
  props: {
    result: {
      type: Object as () => SearchResponseDocument,
      required: true,
      default: () => {},
    },
  },
  setup() {
    const formatDateInput = (input: string) => formatDate(input);
    const getAuthor = (values: KeyValuePair[]) => {
      if (values != null && values.length > 0) {
        const author = values.filter(function (value: KeyValuePair) {
          return value.key.toLowerCase() === "author";
        });
        return author !== null && author !== undefined && author.length > 0
          ? author[0].value
          : "";
      } else {
        ("");
      }
    };
    return {
      formatDateInput,
      getAuthor,
      formatDate,
    };
  },
});
</script>
