<template>
  <a :href="result.url" class="resultItem" :aria-describedby="result.id">
    <h3 class="h5" :id="result.id" v-if="result.title">{{ result.title }}</h3>
    <p><span v-html="highlights"></span></p>
    <ul class="resultTags">
      <li>
        {{ result.url }}
      </li>
    </ul>
  </a>
</template>
<script lang="ts">
import { SearchResponseDocument } from "search-api-client";
import { formatISODateMMDDYYYY } from "@/common/date";
import { defineComponent } from "vue";
import sanitizeHtml from "sanitize-html";

const options: sanitizeHtml.IOptions = {
  allowedTags: ["strong"],
};

export default defineComponent({
  name: "Result",
  props: {
    result: {
      type: Object as () => SearchResponseDocument,
      required: true,
      default: () => {},
    },
  },
  computed: {
    highlights() {
      if (this.result.highlights.description != null) {
        return sanitizeHtml(this.result.highlights.description[0], options);
      } else if (this.result.highlights.content != null) {
        return this.$sanitize(this.result.highlights.content[0], options);
      } else if (this.result.highlights.nestedContent != null) {
        return this.$sanitize(this.result.highlights.nestedContent[0], options);
      }
      return this.result.content;
    },
  },
  methods: {
    formatISODate(input: string) {
      return formatISODateMMDDYYYY(input);
    },
  },
});
</script>
