import { ref } from "vue";
import {
  AzureSearchResponse,
  GenericSearchRequestTaxonomyItem,
  TaxonomyTag,
} from "search-api-client";
import getSearch from "../services/searchClientRepository";

const MIN_KEYWORD_LENGTH_FOR_SEARCH = 3;

const isEmpty = (string1: string) => {
  return string1.length === 0 || !string1.trim();
};

const keyword = ref("" as string);
const keywordInput = ref("" as string);
const currentActive = ref(1 as number);

const isGridView = ref(true);
const isListView = ref(false);

const selectedTags = ref([]);
const selectedYears = ref([]);
const totalPages = ref(0);
const tags = ref([] as TaxonomyTag[]);
const toggleFiltersInView = ref(false);
const suggestedSearchTerms = ref([]);

const firstPage = (currentPage: number, pageSize: number) => {
  return (currentPage - 1) * pageSize + 1;
};
/*Tag methods*/
const convertTagtoSearchTag = (
  tag: TaxonomyTag,
): GenericSearchRequestTaxonomyItem => {
  const response: GenericSearchRequestTaxonomyItem = {
    tagId: tag.id,
    categoryId: tag.categoryId,
  };
  return response;
};
const updateClear = () => {
  currentActive.value = 1;
  selectedTags.value = [];
};
const clearAll = () => {
  tags.value = [];
  selectedYears.value = [];
  updateClear();
};
const updateTags = (updateTags: TaxonomyTag[]) => {
  currentActive.value = 1;
  selectedTags.value = updateTags.map((x) => convertTagtoSearchTag(x));
};
const updateIndexTags = (updateTags: TaxonomyTag[]) => {
  clearAll();
  if (updateTags[0].title == "reset") {
    return;
  }
  currentActive.value = 1;
  selectedTags.value = updateTags.map((x) => convertTagtoSearchTag(x));
};

const updateYearTags = (yearTag) => {
  if (!selectedYears.value.includes(yearTag)) {
    selectedYears.value = [...selectedYears.value, yearTag];
  }
};

const clear = (tag: TaxonomyTag) => {
  tags.value.splice(tags.value.indexOf(tag), 1);
  updateTags(tags.value);
};
const clearDateTag = (tag) => {
  const filtered = selectedYears.value.filter((x) => x !== tag);
  selectedYears.value = filtered;
};

const switchFilterOverlay = (on: boolean) => {
  toggleFiltersInView.value = on;
};
const lastPage = (total: number, pageSize: number): number => {
  return Number(currentActive.value) * pageSize > total
    ? total
    : Number(currentActive.value) * pageSize;
};

const calculateTotalPages = (total: number, pageSize: number): number => {
  return Math.ceil(total / pageSize);
};
const paginationClick = (page: number) => {
  const elmnt = document.getElementById("results");
  if (elmnt) {
    elmnt.scrollIntoView();
  }

  currentActive.value = page;
};

const updateYearClear = () => {
  keyword.value = "";
  keywordInput.value = "";
  currentActive.value = 1;
  selectedYears.value = [];
};
const updateKeyword = () => {
  currentActive.value = 1;
  suggestedSearchTerms.value = [];
  keywordInput.value = keyword.value;
};
const clearKeyword = () => {
  keywordInput.value = "";
  keyword.value = "";
};
const resourcesChanged = (update: AzureSearchResponse, pageSize: number) => {
  const total = update.count !== undefined ? update.count : 0;
  totalPages.value = calculateTotalPages(total, pageSize);
};

const toggleListView = () => {
  isGridView.value = false;
  isListView.value = true;
};
const toggleGridView = () => {
  isGridView.value = true;
  isListView.value = false;
};

const clearSuggestions = () => {
  suggestedSearchTerms.value = [];
};

const setSuggestions = (suggestions?: string[]) => {
  suggestedSearchTerms.value = suggestions;
};

const handleSelectSearchTerm = (value) => {
  clearSuggestions();
  keyword.value = value;
  updateKeyword();
};

const handleKeywordInputChanged = ({
  baseUri,
  index,
  event,
  parentId,
}: {
  baseUri: string;
  index: string;
  event: KeyboardEvent;
  parentId: number;
}) => {
  const element = event.target as HTMLInputElement;
  if (
    !isEmpty(element.value) &&
    element.value.length >= MIN_KEYWORD_LENGTH_FOR_SEARCH &&
    event.code != "Enter"
  ) {
    const Search = getSearch(baseUri as string);
    Search.azureAutocomplete(
      JSON.stringify({
        query: `${element.value}`,
        index,
        filter: `parents/any(p: p eq '${parentId}')`,
      }),
    )
      .then((response) => {
        setSuggestions(response.suggestions);
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    clearSuggestions();
  }
};

export {
  resourcesChanged,
  clearKeyword,
  updateKeyword,
  updateYearClear,
  updateYearTags,
  updateClear,
  updateTags,
  convertTagtoSearchTag,
  paginationClick,
  lastPage,
  switchFilterOverlay,
  clearDateTag,
  clear,
  clearAll,
  firstPage,
  toggleListView,
  toggleGridView,
  updateIndexTags,
  toggleFiltersInView,
  setSuggestions,
  handleSelectSearchTerm,
  clearSuggestions,
  handleKeywordInputChanged,
  tags,
  totalPages,
  selectedYears,
  selectedTags,
  currentActive,
  keywordInput,
  keyword,
  suggestedSearchTerms,
  isGridView,
  isListView,
};
