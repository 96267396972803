<template>
  <li>
    <a class="ctaLink" :href="result.url">
      {{ result.title }}
    </a>
  </li>
</template>
<script lang="ts">
import { KeyValuePair, SearchResponseDocument } from "search-api-client";
import { formatDate, formatISODateMMDDYYYY } from "../common/date";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    result: {
      type: Object as () => SearchResponseDocument,
      required: true,
      default: () => {},
    },
  },
  setup() {
    const getFileSize = (values: KeyValuePair[]) => {
      if (values != null && values.length > 0) {
        const fileSize = values.filter(function (value: KeyValuePair) {
          return value.key === "fileSize";
        });
        return fileSize !== null &&
          fileSize !== undefined &&
          fileSize.length > 0
          ? fileSize[0].value
          : "";
      } else {
        ("");
      }
    };
    return {
      formatDate,
      getFileSize,
      formatISODateMMDDYYYY,
    };
  },
});
</script>
