<template>
  <a :href="result.url" class="item" :aria-label="result.title">
    <div class="itemPreview grid" v-if="result.images.length > 0">
      <img
        :src="result.images[0].url"
        :alt="result.images[0].altText"
        loading="lazy"
      />
    </div>
    <div class="itemPreview list" v-if="result.images.length > 1">
      <img
        :src="result.images[1].url"
        :alt="result.images[1].altText"
        loading="lazy"
      />
    </div>
    <div class="itemInner">
      <h2 class="h4">
        <svg
          aria-hidden="true"
          role="presentation"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.9997 2.49939C9.47685 2.49939 4.99969 6.97624 4.99969 12.4987C4.99969 20.2732 11.3122 20.2107 12.8247 24.7229L13.4622 26.6477C13.6409 27.1794 14.152 27.5269 14.7122 27.4977H15.4122C15.9724 27.5269 16.4835 27.1794 16.6622 26.6477L17.2997 24.7229C18.7497 20.2107 24.9997 20.2732 24.9997 12.4987C24.9997 6.97624 20.5225 2.49939 14.9997 2.49939ZM14.9997 16.2484C12.9286 16.2484 11.2497 14.5696 11.2497 12.4987C11.2497 10.4278 12.9286 8.74896 14.9997 8.74896C17.0708 8.74896 18.7497 10.4278 18.7497 12.4987C18.7497 13.4932 18.3546 14.447 17.6513 15.1502C16.9481 15.8534 15.9943 16.2484 14.9997 16.2484Z"
          />
        </svg>
        {{ result.title }}
      </h2>
      <div class="meta">
        <strong>{{ getReturnPrice(result.customProperties) }}</strong>
        {{ getReturnText(result.customProperties) }}
      </div>
    </div>
    <div v-if="result.taxonomy.length > 0" class="tags">
      <div
        v-for="taxonomy in getActivities(result.customProperties)"
        :key="taxonomy.tag.title"
        class="tag"
      >
        <img
          :src="taxonomy.tag.url"
          role="presentation"
          loading="lazy"
          alt=""
        />
        {{ taxonomy.tag.title }}
      </div>
    </div>
  </a>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { KeyValuePair, SearchResponseDocument } from "search-api-client";
export default defineComponent({
  props: {
    result: {
      type: Object as () => SearchResponseDocument,
      required: true,
      default: () => {},
    },
  },
  setup() {
    const getReturnText = (values: KeyValuePair[]) => {
      if (values != null && values.length > 0) {
        const ReturnText = values.filter(function (value: KeyValuePair) {
          return value.key === "returnText";
        });
        return ReturnText !== null &&
          ReturnText !== undefined &&
          ReturnText.length > 0
          ? ReturnText[0].value
          : "";
      } else {
        ("");
      }
    };
    const getReturnPrice = (values: KeyValuePair[]) => {
      if (values != null && values.length > 0) {
        const ReturnPrice = values.filter(function (value: KeyValuePair) {
          return value.key === "returnPrice";
        });
        return ReturnPrice !== null &&
          ReturnPrice !== undefined &&
          ReturnPrice.length > 0
          ? ReturnPrice[0].value
          : "";
      } else {
        ("");
      }
    };

    const getActivities = (values: KeyValuePair[]) => {
      if (values != null && values.length > 0) {
        const Activities = values.filter(function (value: KeyValuePair) {
          return value.key === "actvities";
        });
        return Activities !== null &&
          Activities !== undefined &&
          Activities.length > 0
          ? JSON.parse(Activities[0].value)
          : "";
      } else {
        ("");
      }
    };

    return {
      getReturnText,
      getReturnPrice,
      getActivities,
    };
  },
});
</script>
