<template>
  <div id="app" class="indexPage">
    <section
      class="ConsumerFilterBanner"
      :class="[{ moreFilterActive: toggleFiltersInView }]"
    >
      <div class="container">
        <RenderResources
          :indexName="index"
          :endPoint="apiendpoint"
          :filteryear="filteryear"
          :baseUri="baseUri"
          :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
          :parentid="parentid"
          :page="1"
          :filtersRequired="1"
        >
          <template v-slot:search="{ result, loading, error }">
            <section class="searchFilterConsumer">
              <div class="error" v-if="error">{{ $t("LoadingError") }}</div>
              <div class="loading" v-if="loading && !error">
                <p>{{ $t("LoadingFilters") }}</p>
              </div>

              <form @submit.prevent="updateKeyword" v-if="!loading && !error">
                <Filter
                  :tagGroups="result.facets"
                  @tagUpdated="updateIndexTags(tags)"
                  @toggleFilters="switchFilterOverlay"
                  :tags="tags"
                  :labelName="title"
                  :label="'all activities'"
                  :useLabelAsFirstOption="false"
                  :filtergroupids="filtergroupids"
                  :filterTagIds="filterTagIds"
                  class="selectWrapper"
                ></Filter>
              </form>

              <p class="preamble" v-if="description">{{ description }}</p>

              <div class="tabsWrapper" v-if="showListGridToggle">
                <button
                  class="tab"
                  :class="{ active: isGridView }"
                  @click="toggleGridView()"
                  ref="gridToggle"
                >
                  <img
                    src="/assets/images/icons/gridView-icon.svg"
                    role="presentation"
                    loading="lazy"
                    alt
                  />
                  Grid view
                </button>

                <button
                  class="tab"
                  :class="{ active: isListView }"
                  @click="toggleListView()"
                  ref="listToggle"
                >
                  <img
                    src="/assets/images/icons/listView-icon.svg"
                    role="presentation"
                    loading="lazy"
                    alt
                  />
                  List view
                </button>
              </div>
            </section>
          </template>
        </RenderResources>
      </div>
    </section>
    <RenderResources
      :parentid="parentid"
      :indexName="index"
      :endPoint="apiendpoint"
      :tags="selectedTags"
      :baseUri="baseUri"
      :years="selectedYears"
      :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
      :page="currentActive"
      :keyword="keywordInput"
      :pagesize="pagesize"
      :sortorder="[orderBy]"
      :maxItems="maxItems"
      @change="resourcesChanged($event, pagesize)"
    >
      <template v-slot:search="{ result, loading, error }">
        <div class="resultSort consumer">
          <div class="container">
            <div class="chosenResults">
              <ChosenTags :tags="tags" v-on:clearTag="clear"></ChosenTags>
              <ChosenDateTags
                :tags="datetags"
                @clearTag="selectedYears"
              ></ChosenDateTags>
              <button
                class="clearAll"
                v-if="tags.length > 0"
                v-on:click="clearAll"
              >
                {{ $t("ClearAll") }}
              </button>
            </div>
            <div class="error" v-if="error">
              {{ $t("LoadingResourceError") }}
            </div>
            <div class="loading" v-if="loading && !error">
              <p>{{ $t("LoadingResults") }}</p>
            </div>
            <div class="gridListItemsWrapper">
              <div
                class="gridListItems gridView"
                :class="{ active: isGridView }"
                ref="gridItems"
                v-if="
                  !loading &&
                  !error &&
                  result.documents !== undefined &&
                  result.documents.length > 0
                "
              >
                <component
                  :is="resultType"
                  v-for="responseValueItem in result.documents"
                  :key="responseValueItem.id"
                  :result="responseValueItem"
                ></component>
              </div>

              <div
                class="gridListItems listView"
                :class="{ active: isListView }"
                ref="listItems"
                v-if="
                  !loading &&
                  !error &&
                  result.documents !== undefined &&
                  result.documents.length > 0
                "
              >
                <component
                  :is="resultType"
                  v-for="responseValueItem in result.documents"
                  :key="responseValueItem.id"
                  :result="responseValueItem"
                ></component>
              </div>
            </div>

            <button
              class="backToTop"
              @click="scrollToTop()"
              v-if="showBackToTop"
            >
              Back to top
              <svg
                aria-hidden="true"
                role="presentation"
                width="13"
                height="15"
                viewBox="0 0 13 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.51812 -0.000546238L7.93234 1.41367L7.93179 1.41422L12.1746 5.657L10.7604 7.07121L7.51801 3.82887L7.51801 14.0356L5.51801 14.0356L5.51801 3.828L2.27548 7.07052L0.86127 5.65631L6.51812 -0.000546238Z"
                />
              </svg>
            </button>

            <div
              class="noResults"
              v-if="
                !loading &&
                !error &&
                (result === undefined || result.count === 0)
              "
            >
              <p>{{ $t("NoResults") }}</p>
            </div>
          </div>
        </div>
      </template>
    </RenderResources>
  </div>
</template>

<script lang="ts">
import Pagination from "v-pagination-3";
import ChosenTags from "../components/ChosenTags.vue";
import ChosenDateTags from "../components/ChosenDateTags.vue";
import Filter from "../components/Filter.vue";
import ResultsIndicator from "../components/ResultsIndicator.vue";
import ResultDestination from "../components/ResultDestination.vue";
import RenderResources from "../components/renderless/RenderResources.vue";
import { defineComponent, ref } from "vue";
import {
  resourcesChanged,
  clearKeyword,
  updateKeyword,
  updateYearClear,
  updateYearTags,
  updateClear,
  updateTags,
  convertTagtoSearchTag,
  paginationClick,
  lastPage,
  switchFilterOverlay,
  clearDateTag,
  clear,
  clearAll,
  firstPage,
  toggleFiltersInView,
  tags,
  totalPages,
  selectedYears,
  updateIndexTags,
  selectedTags,
  currentActive,
  keywordInput,
  keyword,
  toggleListView,
  toggleGridView,
  isGridView,
  isListView,
} from "./common";
export default defineComponent({
  components: {
    Filter,
    ResultsIndicator,
    ResultDestination,
    Pagination,
    RenderResources,
    ChosenTags,
    ChosenDateTags,
  },
  props: {
    showkeywords: {
      type: Boolean,
      required: true,
      default: true,
    },
    filtergroupids: {
      type: [],
      required: true,
      default: [],
    },
    filteryear: {
      type: Boolean,
      required: true,
      default: true,
    },
    parentid: {
      type: Number,
      required: true,
      default: -1,
    },
    pagesize: {
      type: Number,
      required: true,
      default: 20,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    description: {
      type: String,
      required: true,
      default: "",
    },
    resultType: {
      type: String,
      required: true,
      default: "",
    },
    indexName: {
      type: String,
      required: true,
      default: "",
    },
    ocpApimSubscriptionKey: {
      type: String,
      required: false,
      default: "",
    },
    baseUri: {
      type: String,
      required: true,
      default: "",
    },
    showListGridToggle: {
      type: Boolean,
      required: false,
      default: true,
    },
    showBackToTop: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxItems: {
      type: Number,
      required: false,
      default: 0,
    },
    filterTagIds: {
      type: [],
      required: false,
      default: [],
    },
  },
  setup(props) {
    const index = props.indexName;
    const apiendpoint = `${props.baseUri}`;
    const sortOptions = ["A-Z", "Z-A"] as string[];
    const sortOption = ref("A-Z" as string);
    const orderBy = ref("title asc" as string);
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return {
      resourcesChanged,
      clearKeyword,
      updateKeyword,
      updateYearClear,
      updateYearTags,
      updateClear,
      updateTags,
      convertTagtoSearchTag,
      paginationClick,
      lastPage,
      switchFilterOverlay,
      clearDateTag,
      clear,
      clearAll,
      firstPage,
      toggleListView,
      updateIndexTags,
      toggleGridView,
      apiendpoint,
      index,
      toggleFiltersInView,
      tags,
      totalPages,
      orderBy,
      selectedYears,
      selectedTags,
      sortOption,
      sortOptions,
      currentActive,
      keywordInput,
      keyword,
      isGridView,
      isListView,
      scrollToTop,
    };
  },
});
</script>
