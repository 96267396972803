<template>
  <div id="app" class="indexPage">
    <section
      class="filterBanner background-white"
      :class="[{ moreFilterActive: toggleFiltersInView }]"
    >
      <div class="container">
        <RenderResources
          :indexName="index"
          :endPoint="apiendpoint"
          :baseUri="baseUri"
          :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
          :parentid="parentid"
          :page="1"
        >
          <template v-slot:search="{ result, loading, error }">
            <section class="searchFilter searchFilter-news iconVariant">
              <div class="error" v-if="error">{{ $t("LoadingError") }}</div>
              <div class="loading" v-if="loading && !error">
                <p>{{ $t("LoadingFilters") }}</p>
              </div>

              <form @submit.prevent="updateKeyword" v-if="!loading && !error">
                <div class="searchInput iconVariant">
                  <label for="searchFilterInput">{{ $t("SearchLabel") }}</label>
                  <input
                    id="searchFilterInput"
                    @keydown.enter.prevent="updateKeyword"
                    @keyup="handleSearchTextKeyDown"
                    type="search"
                    :placeholder="$t('SearchLabel')"
                    v-model="keyword"
                  />
                  <div
                    class="searchAutocomplete fullWidth"
                    v-if="suggestedSearchTerms.length > 0"
                  >
                    <ul>
                      <li v-for="term in suggestedSearchTerms" :key="term">
                        <button
                          v-on:click="handleSelectSearchTerm(term)"
                          :aria-label="'Search for ' + term"
                        >
                          {{ term }}
                        </button>
                      </li>
                    </ul>
                  </div>
                  <button
                    class="clearButton"
                    v-if="keyword.length > 0"
                    @click="clearKeyword"
                    :aria-label="$t('ClearKeyword')"
                  >
                    <svg
                      id="close"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      preserveAspectRatio="xMinYMid"
                      aria-hidden="true"
                      role="img"
                      fill="#1A1347"
                    >
                      <g>
                        <path
                          d="M315.25 20.25V31H326v1.5h-10.75v10.75h-1.5V32.5H303V31h10.75V20.25h1.5z"
                          transform="translate(-305 -79) translate(0 56) rotate(45 314.5 31.75)"
                        />
                      </g>
                    </svg>
                  </button>
                  <button
                    type="submit"
                    class="searchButton"
                    @click="updateKeyword"
                    :aria-label="$t('SubmitSearch')"
                  >
                    <svg
                      aria-hidden="true"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 18 18"
                    >
                      <path
                        d="M17.875 16.46l-4-4.005c-.13-.132-.274-.25-.43-.35l-1-.69c2.057-2.549 2.076-6.184.046-8.754C10.461.091 6.924-.727 3.973.69 1.023 2.107-.554 5.382.178 8.575c.731 3.194 3.575 5.454 6.847 5.442 1.588 0 3.13-.54 4.37-1.532l.75 1.002c.089.129.19.25.3.36l4 4.005c.094.095.222.148.355.148.133 0 .261-.053.355-.148l.7-.7c.19-.189.199-.493.02-.692zm-10.85-4.445c-2.761 0-5-2.241-5-5.006s2.239-5.006 5-5.006 5 2.241 5 5.006c0 1.328-.527 2.6-1.464 3.54-.938.938-2.21 1.466-3.536 1.466z"
                      />
                    </svg>
                  </button>
                </div>

                <Filters
                  :tagGroups="result.facets"
                  :showYearFilter="filteryear"
                  @tagUpdated="updateTags($event)"
                  @changeDate="updateYearTags($event)"
                  @toggleFilters="switchFilterOverlay"
                  :tags="tags"
                  :datetags="selectedYears"
                  :filtergroupids="filtergroupids"
                  class="selectWrapper"
                ></Filters>
              </form>

              <div
                class="chosenResults"
                v-if="tags.length > 0 || selectedYears.length > 0"
                v-on:clearTag="clear"
              >
                <p
                  v-if="tags.length > 0 || selectedYears.length > 0"
                  v-on:clearTag="clear"
                >
                  Applied filters:
                </p>

                <div class="filterItemWrapper">
                  <ChosenTags :tags="tags" v-on:clearTag="clear"></ChosenTags>
                  <ChosenDateTags
                    :tags="selectedYears"
                    @clearTag="clearDateTag"
                  ></ChosenDateTags>
                </div>
                <button
                  class="clearAll"
                  v-if="tags.length > 0 || selectedYears.length > 0"
                  v-on:click="clearAll"
                >
                  {{ $t("ClearAll") }}
                </button>
              </div>
            </section>
          </template>
        </RenderResources>
      </div>
    </section>
    <RenderResources
      :parentid="parentid"
      :indexName="index"
      :endPoint="apiendpoint"
      :tags="selectedTags"
      :baseUri="baseUri"
      :years="selectedYears"
      :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
      :page="currentActive"
      :keyword="keywordInput"
      :pagesize="pagesize"
      :sortorder="[orderBy]"
      @change="resourcesChanged($event, pagesize)"
    >
      <template v-slot:search="{ result, loading, error }">
        <div class="resultSort" id="results">
          <div class="container">
            <div class="sortInner">
              <div class="form sortBy">
                <label for="indexSortBy">Sort by:</label>
                <select class="select" id="indexSortBy" v-model="orderBy">
                  <option value="created desc">Newest</option>
                  <option value="created asc">Oldest</option>
                </select>
              </div>

              <ResultsIndicator
                v-if="result !== undefined && result.count > 0"
                :first="firstPage(currentActive, pagesize)"
                :term="keywordInput"
                :last="lastPage(result.count, pagesize)"
                :total="result.count"
                :indexTitle="title"
              ></ResultsIndicator>
            </div>

            <div class="error" v-if="error">
              {{ $t("LoadingResourceError") }}
            </div>
            <div class="loading" v-if="loading && !error">
              <p>{{ $t("LoadingResults") }}</p>
            </div>
            <div
              class="newsItems"
              v-if="
                !loading &&
                !error &&
                result.documents !== undefined &&
                result.documents.length > 0
              "
            >
              <div class="inner">
                <component
                  :is="resultType"
                  v-for="responseValueItem in result.documents"
                  :key="responseValueItem.id"
                  :result="responseValueItem"
                ></component>
              </div>
            </div>

            <div
              class="noResults"
              v-if="
                !loading &&
                !error &&
                (result === undefined || result.count === 0)
              "
            >
              <p>{{ $t("NoResults") }}</p>
            </div>
          </div>
        </div>
        <div class="container small">
          <nav
            class="pagination"
            v-if="totalPages > 1"
            role="navigation"
            aria-label="Pagination Navigation"
          >
            <pagination
              v-model="currentActive"
              :records="result.count"
              :per-page="pagesize"
              @paginate="paginationClick"
            />
          </nav>
        </div>
      </template>
    </RenderResources>
  </div>
</template>

<script lang="ts">
import Pagination from "v-pagination-3";

import ChosenTags from "../components/ChosenTags.vue";
import ChosenDateTags from "../components/ChosenDateTags.vue";
import Filters from "../components/Filters.vue";
import ResultsIndicator from "../components/ResultsIndicator.vue";
import ResultNews from "../components/ResultNews.vue";
import RenderResources from "../components/renderless/RenderResources.vue";
import { defineComponent, ref, PropType } from "vue";
import {
  resourcesChanged,
  clearKeyword,
  updateKeyword,
  updateYearClear,
  updateYearTags,
  updateClear,
  updateTags,
  handleSelectSearchTerm,
  handleKeywordInputChanged,
  convertTagtoSearchTag,
  paginationClick,
  lastPage,
  switchFilterOverlay,
  clearDateTag,
  clear,
  clearAll,
  firstPage,
  toggleFiltersInView,
  tags,
  totalPages,
  selectedYears,
  selectedTags,
  currentActive,
  keywordInput,
  keyword,
  suggestedSearchTerms,
} from "./common";
export default defineComponent({
  components: {
    Filters,
    ResultsIndicator,
    ResultNews,
    Pagination,
    RenderResources,
    ChosenTags,
    ChosenDateTags,
  },
  props: {
    showkeywords: {
      type: Boolean,
      required: true,
      default: true,
    },
    filtergroupids: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => [],
    },
    filteryear: {
      type: Boolean,
      required: true,
      default: true,
    },
    parentid: {
      type: Number,
      required: true,
      default: -1,
    },
    ocpApimSubscriptionKey: {
      type: String,
      required: false,
      default: "",
    },
    pagesize: {
      type: Number,
      required: true,
      default: 20,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    resultType: {
      type: String,
      required: true,
      default: "",
    },
    indexName: {
      type: String,
      required: true,
      default: "",
    },
    baseUri: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(props) {
    const index = props.indexName;
    const apiendpoint = `${props.baseUri}`;
    const sortOptions = ["A-Z", "Z-A"] as string[];
    const sortOption = ref("A-Z" as string);
    const orderBy = ref("created desc" as string);

    const handleSearchTextKeyDown = (event: KeyboardEvent) => {
      handleKeywordInputChanged({
        event,
        baseUri: props.baseUri as string,
        index: props.indexName as string,
        parentId: props.parentid as number,
      });
    };

    return {
      resourcesChanged,
      clearKeyword,
      updateKeyword,
      updateYearClear,
      updateYearTags,
      updateClear,
      updateTags,
      handleSelectSearchTerm,
      handleKeywordInputChanged,
      handleSearchTextKeyDown,
      convertTagtoSearchTag,
      paginationClick,
      lastPage,
      switchFilterOverlay,
      clearDateTag,
      clear,
      clearAll,
      firstPage,
      apiendpoint,
      index,
      toggleFiltersInView,
      tags,
      totalPages,
      orderBy,
      selectedYears,
      selectedTags,
      sortOption,
      sortOptions,
      currentActive,
      keywordInput,
      keyword,
      suggestedSearchTerms,
    };
  },
});
</script>
