<template>
  <div>
    <label :for="removeWhitespace(name)">{{ name }}</label>
    <select v-model="selectedOption" :id="removeWhitespace(name)">
      <option :value="null" default>{{ name }}</option>
      <option v-for="option in options" :value="option" :key="option.value">
        {{ option.value.getFullYear() }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { GenericSearchResponseFacetsCreatedItem } from "search-api-client";

import { defineComponent, ref, watch } from "vue";
export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
      default: "Publish date",
    },
    open: {
      type: Boolean,
    },
    options: {
      type: Array as () => GenericSearchResponseFacetsCreatedItem[],
      required: true,
      default: () => [],
    },
    active: {
      type: Array as () => GenericSearchResponseFacetsCreatedItem[],
      required: true,
      default: () => [],
    },
  },
  setup(props, context) {
    const selectedOption = ref(null as GenericSearchResponseFacetsCreatedItem);

    const isChecked = (value: Date): boolean => {
      return (
        (props.active as GenericSearchResponseFacetsCreatedItem[])
          .map(function (e: GenericSearchResponseFacetsCreatedItem) {
            return e.value;
          })
          .indexOf(value) > -1
      );
    };
    const showCheckboxes = () => {
      context.emit("toggleFilters");
    };
    const removeWhitespace = (a) => a.toLowerCase().split(" ").join("");

    watch(
      () => selectedOption.value,
      (newVal) => {
        if (newVal !== null) {
          const emitObject = {
            value: newVal.value,
            count: newVal.count,
          } as GenericSearchResponseFacetsCreatedItem;
          context.emit("inputChange", emitObject);
          selectedOption.value = null;
        }
      },
    );

    return {
      removeWhitespace,
      showCheckboxes,
      isChecked,
      selectedOption,
    };
  },
});
</script>
