const defaultShowKeywords = true;
const defaultFilterYear = true;
const defaultFilterGroupIds = [];
const defaultParentId = -1;
const defaultPageSize = 20;
const defautresultType = "Result";
const defaultShowListGridToggle = true;
const defaultMaxItems = 0;
const defaultShowBackToTop = true;
const defaultFilterTagIds = [];

const indexElement = document.getElementById("sm-resource-index");

const showKeywords =
  indexElement?.getAttribute("data-showkeywords") == null
    ? defaultShowKeywords
    : indexElement.getAttribute("data-showkeywords")?.toLowerCase() === "true";
const filterYear =
  indexElement?.getAttribute("data-filteryear") == null
    ? defaultFilterYear
    : indexElement.getAttribute("data-filteryear")?.toLowerCase() === "true";
let filterGroupIds = defaultFilterGroupIds;
const filterGroupIdsDataStr = indexElement?.getAttribute("data-filtergroupids");
const filterGroupIdsData = filterGroupIdsDataStr
  ? filterGroupIdsDataStr.split(",")
  : filterGroupIds;
if (filterGroupIdsData.length > 0) filterGroupIds = filterGroupIdsData;
const showListGridToggle =
  indexElement?.getAttribute("data-showListGridToggle") == null
    ? defaultShowListGridToggle
    : indexElement.getAttribute("data-showListGridToggle")?.toLowerCase() ===
      "true";
const showBackToTop =
  indexElement?.getAttribute("data-showBackToTop") == null
    ? defaultShowBackToTop
    : indexElement.getAttribute("data-showBackToTop")?.toLowerCase() === "true";
const resultType =
  indexElement?.getAttribute("data-resulttype") ?? defautresultType;
let filterTagIds = defaultFilterTagIds;
const filterTagIdsDataStr = indexElement?.getAttribute("data-filtertagids");
const filterTagIdsData = filterTagIdsDataStr
  ? filterTagIdsDataStr.split(",")
  : filterTagIds;
if (filterTagIdsData.length > 0) filterTagIds = filterTagIdsData;

let parentId = defaultParentId;
const castParentId = Number(indexElement?.getAttribute("data-parentid"));
if (!isNaN(castParentId) && castParentId !== 0) parentId = castParentId;

let pageSize = defaultPageSize;
const castPageSize = Number(indexElement?.getAttribute("data-pagesize"));
if (!isNaN(castPageSize) && castPageSize !== 0) pageSize = castPageSize;

let maxItems = defaultMaxItems;
const castMaxItems = Number(indexElement?.getAttribute("data-maxItems"));
if (!isNaN(castMaxItems) && castMaxItems !== 0) maxItems = castMaxItems;

const title = indexElement?.getAttribute("data-title");
const description = indexElement?.getAttribute("data-description");
const key = indexElement?.getAttribute("data-ocpApimSubscriptionKey");

const index = indexElement?.getAttribute("data-index");
const url = indexElement?.getAttribute("data-baseuri");
const config = {
  showkeywords: showKeywords,
  filtergroupids: filterGroupIds,
  filteryear: filterYear,
  parentid: parentId,
  pagesize: pageSize,
  title: title,
  description: description,
  resultType: resultType,
  indexName: index,
  ocpApimSubscriptionKey: key,
  baseUri: url,
  maxItems: maxItems,
  showListGridToggle: showListGridToggle,
  showBackToTop: showBackToTop,
  filterTagIds: filterTagIds,
};

export default config;
